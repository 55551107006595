import React from "react";
import { graphql } from 'gatsby';

import styled from "styled-components";
import breakpoints from "../utils/breakpoints";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import IntroduceYourself from "../components/introduce-yourself";
import Disclaimer from "../components/disclaimer";

const Columns = styled.div`
  display:flex;
  flex-wrap:wrap;
  width:100%;
  padding: 6.1rem var(--grid-padding) 5.7rem;
  margin: 0px 0;

  @media ${breakpoints.tabletSm} {
    padding: 4rem var(--grid-padding);
    margin: 0px -4.5rem;
  }
`;

const Column = styled.div`
  width: 100%;
  flex-basis: 100%;
  padding: 0px 0;
  color: var(--brand-blue);

  @media ${breakpoints.tabletSm} {
    width: 50%;
    flex-basis: 50%;
    padding: 0px 3.2rem;
  }
  @media ${breakpoints.tablet} {
    font-size: 1.9rem;
    padding: 0px 4.5rem;
  }

  a{
    text-decoration:underline;
  }
`;

export default function Introduce({ path, data: { cms: { seo, entry }} }) {

  return (
    <Layout theme="dark">
      <SEO
        title={seo.og_title}
        description={seo.og_description}
        meta={[
          {
            "property": "og:image",
            "content": "/images/og-updated.png"
          }
        ]}
      />

      <Hero heading={entry.headline} />
      <Columns>
        <Column dangerouslySetInnerHTML={{ __html: entry.body_left }}></Column>
        <Column dangerouslySetInnerHTML={{ __html: entry.body_right }}></Column>
      </Columns>
      <IntroduceYourself />
    </Layout>
  );
}

export const query = graphql`
  query connect {
    cms {
      entry(id: "cb6baa66-e54a-4207-92be-5e5ad46c1681") {
        ... on cms_Entry_Pages_PageConnect {
          id
          title
          headline
          body_left
          body_right
        }
      }
      seo: SeoMeta(page_id: "cb6baa66-e54a-4207-92be-5e5ad46c1681") {
        og_description
        og_title
        title
      }
    }
  }
`;
